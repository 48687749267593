<template>
    <div>
        <b-modal :id="`modal-news-${newsId}`" centered size="lg" hide-header @hidden="onHidden">
            <div class="modal-body" :class="[news.length >= 0 ? 'flex-center' : '']">
                <b-spinner variant="primary" label="Spinning" v-if="news.length == 0"></b-spinner>
                <div v-if="news.length != 0">
                    <div class="popup">
                        <h3>{{ year }}.{{ month }}.{{ day }}</h3>
                        <h4 class="modal-title text-center mb-3">{{ news.message_title }}</h4>
                    </div>
                    <div class="card mb-3">
                        <div class="card-body ck-content">
                            <p v-html="news.message_content"></p>
                        </div>
                    </div>

                    <div class="text-right">
                        <b-button variant="secondary" @click="toggleModal">關閉</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'NewsModal',
    props: {
        NewsModelList: [

        ],
        newsId:Number
    },
    data() {
        return {
            year: 0,
            month: 0,
            day: 0,
            news: []
        };
    },
    computed: {
        getNewsData() {
            if (this.NewsModelList.length > 0) {
                let date = this.NewsModelList.post_date.split('-')
                let year = date[0]
                let month = date[1]
                let day = date[2].split(' ')[0].substring(0, 2)

                return [year, month, day]
            } else {
                return 0;
            }
        }
    },
    methods: {
        toggleModal() {
            this.$root.$emit('bv::toggle::modal', `modal-news-${this.newsId}`)
        },
        onHidden() {
            console.log('close')
            this.news = []
        }

    },
    watch: {
        NewsModelList(newValue, oldValue) {
            // 當 counter 屬性改變時觸發
            this.news = this.NewsModelList;
            if (newValue != '') {
                let date = this.NewsModelList.post_date.split('-')
                this.year = date[0]
                this.month = date[1]
                this.day = date[2].split(' ')[0].substring(0, 2)
            }

            console.log(oldValue)
        }
    },
}
</script>

<style scoped>
.news-modal-title {
    font-size: 20px !important;
}

.spinner-border.text-primary {
    margin: 10rem 0;
}

.btn-secondary {
    background-color: #6c757d;
}

.popup h2,
h3 {
    color: #33ccff !important
}

.popup h2 {
    text-align: center;
    font-weight: 100;
    font-size: 5.313em;
    letter-spacing: 3px;
    margin: 30px 0 0 0;
    line-height: 0.7em;
}

.popup h3 {
    text-align: center;
    font-weight: 100;
    font-size: 1.700em;
    margin: 10px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<style lang="css" src="assets/css/ckeditor.css"></style>

